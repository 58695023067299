* {
  font-family: "open sans", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#legend {
  position: absolute;
  z-index: 9999;
  top: 0.65rem;
  right: 3rem;
  width: 120px;
  background: rgba(0, 0, 0, 0.4);
  font-size: 0.8rem;
  font-family: Tahoma, sans-serif;
  padding: 0.5rem;
  border: 3px solid rgba(1, 1, 1, 0.5);
  border-radius: 6px;
}

.level-color {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.color {
  border-radius: 3px;
  width: 2rem;
  height: 0.8rem;
  justify-self: left;
  margin-bottom: 0.5rem;
}

.color-last {
  margin-bottom: 0;
}

.level-color-light-magenta {
  background-color: rgba(255, 120, 255, 0.3);
  border: 1px solid rgba(255, 120, 255, 0.7);
}

.level-color-magenta {
  background-color: rgba(255, 0, 255, 0.3);
  border: 1px solid rgba(255, 0, 255, 0.7);
}

.level-color-red {
  background-color: rgba(240, 15, 15, 0.3);
  border: 1px solid rgba(240, 15, 15, 0.7);
}

.level-color-orange {
  background-color: rgba(255, 126, 0, 0.3);
  border: 1px solid rgba(255, 126, 0, 0.7);
}

.level-color-yellow {
  background-color: rgba(255, 255, 0, 0.3);
  border: 1px solid rgba(255, 255, 0, 0.7);
}

.level-name {
  justify-content: right;
}

.tooltip-location {
  width: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .address-waze {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.radar-tooltip .maplibregl-popup-content {
  background: transparent;
  width: 150px;
  box-shadow: none;
  z-index: 9999;
}

.radar-tooltip .maplibregl-popup-tip {
  display: none;
  z-index: 9999;
}

.radar-choice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
}

.maplibregl-popup,
.radar-tooltip,
.maplibregl-popup-anchor-bottom {
  z-index: 9999;
}

#map-styler {
  border-radius: 15%;
  border: 2px solid rgba(120, 120, 120, 0.2);
  background-color: white;
  position: absolute;
  right: 0.54rem;
  top: 11.75rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-clip: padding-box;
  z-index: 99999;
  width: 2rem;
  height: 2rem;

  &:hover {
    cursor: pointer;
    background-color: rgb(240, 240, 240);
  }
}

.location-toggler {
  z-index: 99999;

  &:hover {
    cursor: pointer;
    background-color: rgb(240, 240, 240);
  }
}

#canvasmap {
  background: transparent;
  position: relative;
  z-index: 1;
  pointer-events: none;
  opacity: 0.5;
}

.polygon-toggler {
  border-radius: 20%;
  border: 2px solid rgba(120, 120, 120, 0.2);
  background-color: white;
  position: absolute;
  right: 0.54rem;
  top: 14rem;
  padding: 0.36rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-clip: padding-box;

  &:hover {
    cursor: pointer;
    background-color: rgb(240, 240, 240);
  }
}

.polyline-toggler {
  border-radius: 20%;
  border: 2px solid rgba(120, 120, 120, 0.2);
  background-color: white;
  position: absolute;
  right: 0.54rem;
  top: 16.5rem;
  padding: 0.36rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-clip: padding-box;

  &:hover {
    cursor: pointer;
    background-color: rgb(240, 240, 240);
  }
}

#hamburger-menu {
  position: absolute;
  top: 0.6rem;
  left: 0.6rem;
  z-index: 99999;
  cursor: pointer;
  padding: 0.5rem;
  margin: 0;
  border: 3px solid black;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255);
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  width: 300px;
  height: 100vh;
  top: 0;
  left: -300px;
  right: 0;
  bottom: 0;
  transition: left 0.35s;
  border-radius: 4px;
  font-family: "open sans", sans-serif;
  background-image: linear-gradient(to top left, rgba(50, 50, 50, 0.92), rgba(0, 5, 0, 0.92));
  color: white;
  z-index: 99999;

  .menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 0.5rem;
    height: 1.5rem;
  }
}

.settings {
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed; /* Use fixed positioning to keep the element relative to the viewport */
  width: 200px;
  bottom: 0;
  right: -200px; /* Position it completely off-screen */
  transition: transform 0.35s, right 0.35s;
  border-radius: 4px;
  font-family: "open sans", sans-serif;
  background-image: linear-gradient(to top left, rgba(50, 50, 50, 0.92), rgba(0, 5, 0, 0.92));
  color: rgb(214, 214, 214);
  z-index: 99999;
  text-align: right;

  h3 {
    margin-bottom: 1rem;
  }

  h5 {
    margin-bottom: 0.15rem;
  }
}

.version {
  font-size: 0.5rem;
}

.menu-hidden {
  left: -300px;
}

.settings-hidden {
  right: -200px;
}

.menu-on {
  left: 0;
}

.settings-on {
  right: 0;
}

#close-menu {
  &:hover {
    cursor: pointer;
  }
}

.welcome {
  padding-left: 1rem;
  height: 5rem;
}

#menu-open {
  left: 0;
}

#settings-open {
  right: 0;
}

.menu-items {
  height: calc(100vh - 5rem - 8rem);
  overflow: auto;
}

.menu-item {
  border-top: 1px solid grey;
  height: 2rem;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.menu-item-toggle {
  display: flex;
  align-items: center;
  height: 100%;
}

.toggler {
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 99999;

  &:hover {
    cursor: pointer;
  }
}

.menu-item:last-child {
  border-top: 1px solid grey;
  height: 2rem;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.submenu-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;
  text-align: right;
}

.submenu-group {
  margin-bottom: 2rem;
}

.tooltip {
  width: 250px;
  overflow: hidden;

  .img {
    width: 240px;
  }
}

.custom-popup {
  font-family: Arial, sans-serif;
  background-color: rgba(255, 0, 255, 0.2); /* White background with 80% opacity */
  color: black;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.custom-popup h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.custom-popup p {
  margin: 5px 0 0;
  font-size: 14px;
}

#timestamp {
  display: flex;
  justify-content: center;
}

#time {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.5rem;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
  font-weight: bolder;
}

#colorscale {
  display: none;
  justify-content: center;
  width: 100vw;
}

#scale {
  display: flex;
  justify-content: center;
  width: 500px;
  min-width: 200px;
  z-index: 9999;
}

#scale img {
  width: 100%;
  height: auto;
  display: block;
}

.attribution {
  font-family: "open sans", sans-serif;
  color: rgba(180, 180, 180, 0.7);
  font-size: 0.65rem;
  text-align: left;
  z-index: 9999;
  bottom: 0;
  left: 0;
  padding: 0.5rem;
}

.attribution-entry {
  margin-bottom: 0.25rem;
}

.attribution-bottom {
  margin-bottom: 0;
}

.bref {
  cursor: pointer;
}

.bvel {
  cursor: pointer;
}

#spinner {
  position: absolute;
  top: 0.4rem;
  right: 3rem;
  display: none;
}

.choice-circle {
  display: flex;
  padding: 0.25rem;
  background-color: rgb(75, 75, 75);
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 9999999;
}

#bottom {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  width: 100%;
}

#controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: black;
  color: rgb(200, 200, 200);
  font-size: x-large;
  z-index: 9999;
}

#radarcontrol {
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 0.25rem;
  align-self: center;
  justify-self: center;
  position: relative; /* Added */
}

#settingscontrol {
  display: flex;
  justify-content: flex-end;
  padding: 0.25rem;
  align-items: center;
  position: absolute; /* Added */
  right: 0; /* Added */
  cursor: pointer;
}

.control {
  padding: 0.25rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  transition: background-color 0.075s;
  cursor: pointer;
}

.control:hover {
  background-color: rgb(150, 150, 150);
}

.control.inactive:hover {
  background-color: rgb(35, 35, 35);
}

#radarcontrol i.control {
  width: 40px; /* Ensure all icons have the same width */
  text-align: center; /* Center the icons within the allocated space */
  font-size: 24px; /* Adjust font size as needed */
}

#playpause {
  font-size: 24px; /* Adjust font size as needed */
  width: 40px; /* Ensure the width matches the width set above */
  text-align: center; /* Center the icon */
}

/* Inactive control style */
#radarcontrol .control.inactive {
  color: rgb(60, 60, 60); /* Darker color for inactive controls */
  cursor: default; /* Show not-allowed cursor for inactive controls */
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #444;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Tablet view */
@media (max-width: 1024px) {
  #radarcontrol i.control {
    width: 30px; /* Smaller width for tablet */
    font-size: 20px; /* Smaller font size for tablet */
  }

  #playpause {
    font-size: 20px; /* Smaller font size for tablet */
    width: 30px; /* Ensure the width matches the width set above */
  }
}

/* Phone view */
@media (max-width: 768px) {
  #radarcontrol i.control {
    width: 25px; /* Smaller width for phone */
    font-size: 18px; /* Smaller font size for phone */
  }

  #playpause {
    font-size: 18px; /* Smaller font size for phone */
    width: 25px; /* Ensure the width matches the width set above */
  }
}

#settingscontrol img {
  display: block;
}

.settings-entries {
  opacity: 1;
}

.settings-entry {
  margin-bottom: 0;
}

.settings-entry-last {
  margin-bottom: 0;
}

.radar-speed-slider-container .radar-pauze-slider-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.radar-speed-annotation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: small;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  background: linear-gradient(90deg, rgb(117, 252, 117) 60%, rgb(120, 120, 120) 60%);
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 12px;
  box-shadow: 0 1px 10px 1px black;
  margin-bottom: 0.5rem;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 50%;
}

.mapboxgl-ctrl-group button,
.maplibregl-ctrl-group button {
  width: 2rem;
  height: 2rem;
}

.setting-text {
  font-size: small;
  color: rgb(150, 150, 150);
}

.setting-annotation-text {
  color: rgb(150, 150, 150);
}

#screen-attribution {
  font-family: "open sans", sans-serif;
  position: absolute;
  bottom: 3rem;
  left: 0.5rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.75rem;
  text-align: left;
  z-index: 99;
}

.screen-attribution-entry {
  margin: 0.25rem 0;
}

.screen-attribution-bottom {
  margin-bottom: 0;
}

.submenu-content {
  display: none;
  overflow: hidden;
  transition: max-height 5s ease-out;
}

.submenu-group {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.submenu-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.6rem;
}

.toggle-icon {
  transition: transform 0.3s ease;
}

.submenu-header.active .toggle-icon {
  transform: rotate(180deg);
}

span.chevron {
  margin-left: 0.5rem;
}

div.header-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
